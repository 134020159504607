import {
  ADD_AUCTION,
  ADD_AUCTION_ERROR,
  ADD_AUCTION_SUCCESS,
  ADD_AUCTION_TYPE,
  ADD_AUCTION_TYPE_ERROR,
  ADD_AUCTION_TYPE_SUCCESS,
  AUCTION_ANALYTICS,
  AUCTION_ANALYTICS_ERROR,
  AUCTION_ANALYTICS_SUCCESS,
  CANCEL_AUCTION,
  CANCEL_AUCTION_ERROR,
  CANCEL_AUCTION_SUCCESS,
  DELETE_AUCTION,
  DELETE_AUCTION_ERROR,
  DELETE_AUCTION_SUCCESS,
  DELETE_AUCTION_TYPE,
  DELETE_AUCTION_TYPE_ERROR,
  DELETE_AUCTION_TYPE_SUCCESS,
  GET_ALL_AUCTION_TYPE,
  GET_ALL_AUCTION_TYPE_ERROR,
  GET_ALL_AUCTION_TYPE_SUCCESS,
  GET_AUCTION,
  GET_AUCTIONS,
  GET_AUCTIONS_ERROR,
  GET_AUCTIONS_SUCCESS,
  GET_AUCTION_CATEGORY,
  GET_AUCTION_CATEGORY_ERROR,
  GET_AUCTION_CATEGORY_SUCCESS,
  GET_AUCTION_ERROR,
  GET_AUCTION_SUCCESS,
  PROCESS_ETH,
  PROCESS_ETH_ERROR,
  PROCESS_ETH_SUCCESS,
  START_AUCTION,
  START_AUCTION_ERROR,
  START_AUCTION_SUCCESS,
  UPDATE_AUCTION,
  UPDATE_AUCTION_ERROR,
  UPDATE_AUCTION_SUCCESS,
  UPLOAD_AUCTION_IMAGE,
  UPLOAD_AUCTION_IMAGE_ERROR,
  UPLOAD_AUCTION_IMAGE_SUCCESS,
  UPLOAD_AUCTION_VIDEO,
  UPLOAD_AUCTION_VIDEO_ERROR,
  UPLOAD_AUCTION_VIDEO_SUCCESS,
} from './constants.auction';

const INIT_STATE = {
  auctions: [],
  auctioncategories: [],
  imageData: {},
  loading: false,
  errorMessage: '',
  successMesage: '',
  metadata: {},
  auctionTypes: [],
  analyticsLoading: false,
  analyticsData: [],
  analyticsMetaData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUCTIONS:
      return {
        ...state,
        loading: true,
        auctions: [],
        successMesage: '',
        errorMessage: '',
      };
    case GET_AUCTIONS_SUCCESS:
      return {
        ...state,
        auctions: action.payload?.data?.Auctions?.length
          ? [...action.payload.data.Auctions]
          : [],
        metadata: action.payload.metadata,
        loading: false,
        errorMessage: '',
      };
    case GET_AUCTIONS_ERROR:
      return {
        ...state,
        successMesage: '',
        errorMessage: action.payload?.message,
        loading: false,
      };
    case ADD_AUCTION:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case ADD_AUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        successMesage: action.payload?.message,
      };
    case ADD_AUCTION_ERROR:
      return {
        ...state,
        errorMessage: action.payload?.message,
        loading: false,
        successMesage: '',
      };
    case DELETE_AUCTION:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case DELETE_AUCTION_SUCCESS:
      return {
        ...state,
        successMesage: action.payload?.message,
        errorMessage: '',
        loading: false,
      };
    case DELETE_AUCTION_ERROR:
      return {
        ...state,
        successMesage: '',
        errorMessage: action.payload,
        loading: false,
      };
    case UPDATE_AUCTION:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case UPDATE_AUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        successMesage: action.payload?.message,
      };
    case UPDATE_AUCTION_ERROR:
      return {
        ...state,
        errorMessage: action.payload?.message,
        loading: false,
        successMesage: '',
      };
    case GET_AUCTION:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case GET_AUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successMesage: '',
        errorMessage: '',
      };
    case GET_AUCTION_ERROR:
      return {
        ...state,
        successMesage: '',
        errorMessage: action.payload?.message,
        loading: false,
      };
    case GET_AUCTION_CATEGORY:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case GET_AUCTION_CATEGORY_SUCCESS:
      return {
        ...state,
        auctioncategories: [...action.payload.data],
        loading: false,
        errorMessage: '',
      };
    case GET_AUCTION_CATEGORY_ERROR:
      return {
        ...state,
        errorMessage: action.payload?.message,
        loading: false,
        successMesage: '',
      };
    case UPLOAD_AUCTION_IMAGE:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case UPLOAD_AUCTION_IMAGE_SUCCESS:
      return {
        ...state,
        successMesage: action.payload?.message,
        imageData: action.payload.data,
        loading: false,
        errorMessage: '',
      };
    case UPLOAD_AUCTION_IMAGE_ERROR:
      return {
        ...state,
        errorMessage: action.payload?.message,
        loading: false,
        successMesage: '',
      };
    case UPLOAD_AUCTION_VIDEO:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case UPLOAD_AUCTION_VIDEO_SUCCESS:
      return {
        ...state,
        successMesage: action.payload?.message,
        imageData: action.payload.data,
        loading: false,
        errorMessage: '',
      };
    case UPLOAD_AUCTION_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
        successMesage: '',
      };
    case GET_ALL_AUCTION_TYPE:
      return {
        ...state,
        loading: true,
        auctionTypes: action.payload,
        successMesage: '',
        errorMessage: '',
      };
    case GET_ALL_AUCTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        auctionTypes: action.payload.data,
        successMesage: '',
        errorMessage: '',
      };
    case GET_ALL_AUCTION_TYPE_ERROR:
      return {
        ...state,
        loading: false,

        successMesage: '',
        errorMessage: action.payload?.message,
      };

    case ADD_AUCTION_TYPE:
      return {
        ...state,
        loading: true,

        successMesage: '',
        errorMessage: '',
      };
    case ADD_AUCTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,

        successMesage: action.payload,
        errorMessage: '',
      };
    case ADD_AUCTION_TYPE_ERROR:
      return {
        ...state,
        loading: false,

        successMesage: '',
        errorMessage: action.payload,
      };

    case DELETE_AUCTION_TYPE:
      return {
        ...state,
        loading: true,

        successMesage: '',
        errorMessage: '',
      };
    case DELETE_AUCTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,

        successMesage: action.payload,
        errorMessage: '',
      };
    case DELETE_AUCTION_TYPE_ERROR:
      return {
        ...state,
        loading: false,

        successMesage: '',
        errorMessage: action.payload,
      };
    case START_AUCTION:
      return {
        ...state,
        successMesage: '',
        errorMessage: '',
      };
    case START_AUCTION_SUCCESS:
      return {
        ...state,
        successMesage: action.payload.message,
      };
    case START_AUCTION_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
      };
    case CANCEL_AUCTION:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case CANCEL_AUCTION_SUCCESS:
      return {
        ...state,
        successMesage: action.payload?.message,
        errorMessage: '',
        loading: false,
      };
    case CANCEL_AUCTION_ERROR:
      return {
        ...state,
        successMesage: '',
        errorMessage: action.payload,
        loading: false,
      };
    case PROCESS_ETH:
      return {
        ...state,
        loading: true,
        successMesage: '',
        errorMessage: '',
      };
    case PROCESS_ETH_SUCCESS:
      return {
        ...state,
        successMesage: action.payload?.message,
        errorMessage: '',
        loading: false,
      };
    case PROCESS_ETH_ERROR:
      return {
        ...state,
        successMesage: '',
        errorMessage: action.payload?.message,
        loading: false,
      };

    case AUCTION_ANALYTICS:
      return {
        ...state,
        analyticsLoading: true,
        analyticsData: [],
        analyticsMetaData: {},
      };
    case AUCTION_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsLoading: false,
        analyticsData: action.payload.data,
        analyticsMetaData: action.payload.metadata,
      };
    case AUCTION_ANALYTICS_ERROR:
      return {
        ...state,
        analyticsLoading: false,
        analyticsData: [],
        analyticsMetaData: {},
      };

    default:
      return { ...state };
  }
};
