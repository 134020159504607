export const GET_AUCTIONS = 'GET_AUCTIONS';
export const GET_AUCTIONS_SUCCESS = 'GET_AUCTIONS_SUCCESS';
export const GET_AUCTIONS_ERROR = 'GET_AUCTIONS_ERROR';

export const GET_AUCTION = 'GET_AUCTION';
export const GET_AUCTION_SUCCESS = 'GET_AUCTION_SUCCESS';
export const GET_AUCTION_ERROR = 'GET_AUCTION_ERROR';

export const ADD_AUCTION = 'ADD_AUCTION';
export const ADD_AUCTION_SUCCESS = 'ADD_AUCTION_SUCCESS';
export const ADD_AUCTION_ERROR = 'ADD_AUCTION_ERROR';

export const DELETE_AUCTION = 'DELETE_AUCTION';
export const DELETE_AUCTION_SUCCESS = 'DELETE_AUCTION_SUCCESS';
export const DELETE_AUCTION_ERROR = 'DELETE_AUCTION_ERROR';

export const UPDATE_AUCTION = 'UPDATE_AUCTION';
export const UPDATE_AUCTION_SUCCESS = 'UPDATE_AUCTION_SUCCESS';
export const UPDATE_AUCTION_ERROR = 'UPDATE_AUCTION_ERROR';

export const GET_AUCTION_CATEGORY = 'GET_AUCTION_CATEGORY';
export const GET_AUCTION_CATEGORY_SUCCESS = 'GET_AUCTION_CATEGORY_SUCCESS';
export const GET_AUCTION_CATEGORY_ERROR = 'GET_AUCTION_CATEGORY_ERROR';

export const UPLOAD_AUCTION_IMAGE = 'UPLOAD_AUCTION_IMAGE';
export const UPLOAD_AUCTION_IMAGE_SUCCESS = 'UPLOAD_AUCTION_IMAGE_SUCCESS';
export const UPLOAD_AUCTION_IMAGE_ERROR = 'UPLOAD_AUCTION_IMAGE_ERROR';

export const UPLOAD_AUCTION_VIDEO = 'UPLOAD_AUCTION_VIDEO';
export const UPLOAD_AUCTION_VIDEO_SUCCESS = 'UPLOAD_AUCTION_VIDEO_SUCCESS';
export const UPLOAD_AUCTION_VIDEO_ERROR = 'UPLOAD_AUCTION_VIDEO_ERROR';

export const ADD_AUCTION_TYPE = 'ADD_AUCTION_TYPE';
export const ADD_AUCTION_TYPE_SUCCESS = 'ADD_AUCTION_TYPE_SUCCESS';
export const ADD_AUCTION_TYPE_ERROR = 'ADD_AUCTION_TYPE_ERROR';

export const GET_ALL_AUCTION_TYPE = 'GET_ALL_AUCTION_TYPE';
export const GET_ALL_AUCTION_TYPE_SUCCESS = 'GET_ALL_AUCTION_TYPE_SUCCESS';
export const GET_ALL_AUCTION_TYPE_ERROR = 'GET_ALL_AUCTION_TYPE_ERROR';

export const DELETE_AUCTION_TYPE_ERROR = 'DELETE_AUCTION_TYPE_ERROR';
export const DELETE_AUCTION_TYPE_SUCCESS = 'DELETE_AUCTION_TYPE_SUCCESS';
export const DELETE_AUCTION_TYPE = 'DELETE_AUCTION_TYPE';

export const START_AUCTION = 'START_AUCTION';
export const START_AUCTION_SUCCESS = 'START_AUCTION_SUCCESS';
export const START_AUCTION_ERROR = 'START_AUCTION_ERROR';

export const CANCEL_AUCTION = 'CANCEL_AUCTION';
export const CANCEL_AUCTION_SUCCESS = 'CANCEL_AUCTION_SUCCESS';
export const CANCEL_AUCTION_ERROR = 'CANCEL_AUCTION_ERROR';

export const PROCESS_ETH = 'PROCESS_ETH';
export const PROCESS_ETH_SUCCESS = 'PROCESS_ETH_SUCCESS';
export const PROCESS_ETH_ERROR = 'PROCESS_ETH_ERROR';

export const AUCTION_ANALYTICS = 'AUCTION_ANALYTICS';
export const AUCTION_ANALYTICS_SUCCESS = 'AUCTION_ANALYTICS_SUCCESS';
export const AUCTION_ANALYTICS_ERROR = 'AUCTION_ANALYTICS_ERROR';

export const AUCTION_ANALYTICS_COUNT = 'AUCTION_ANALYTICS_COUNT';

export const DASHBOARD_AUCTION_COUNT = 'DASHBOARD_AUCTION_COUNT';

export const DASHBOARD_CHART = 'DASHBOARD_CHART';
